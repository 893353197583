<template>
	<div class="category" :class="{'page--padding':flavour}">
		<section class="section--page relative">
			<v-container>
				<h1 class="text-center">{{item.NAME}}</h1>
				<h4 v-if="item.UF_SUBTITLE" class="text-center color-black mt-1">{{item.UF_SUBTITLE}}</h4>

				<div v-if="isMiniPralineCategory" class="category__header">
					<div>
						<div class="size4">
							Мы создали мини-версии наших пралине,<br/>
							чтобы вы могли попробовать больше вкусов и выбрать любимый
<!--							<template v-if="flavour">
								<br />
								<span>Выбранная конфетка уже внутри.</span>
							</template>-->
						</div>
					</div>
				</div>

        <div v-if="isPralineCategory" class="category__header">
          <div>
            <div class="size4">
              Конфета СЧАСТЬЯ — это классический рецепт французского пралине
              с оригинальным вкусом, приготовленного из натуральных ингредиентов.
              Мы создаем каждый кубик с любовью, потому что знаем, что наши
              эмоции так же сильно влияют на качество продукта, как и сами ингредиенты.
              <!--							<template v-if="flavour">
                              <br />
                              <span>Выбранная конфетка уже внутри.</span>
                            </template>-->
            </div>
          </div>
        </div>

			</v-container>

			<product-list v-if="filteredProducts && filteredProducts.length"
						  :items="filteredProducts" :category="item" :flavour="flavour"/>
			<p v-else class="text-center">We apologize, this section is under construction</p>
		</section>
	</div>
</template>

<script>

	import ProductList from "@/views/Category/product-list";

  const PRALINE_CATEGORY_ID = 5;
  const MINI_PRALINE_CATEGORY_ID = 14;

	export default {
		name: "Category",
		components: {
			ProductList,
		},
		props: {
			code: {
				default: null,
				type: String,
			}
		},
		data() {
			return {
				filters: null,
				/*filters: [
					{min: 0, max: 4, name: "2-4", isActive: false},
					{min: 6, max: 9, name: "6-9", isActive: false},
					{min: 18, max: 1000, name: "18+", isActive: false},
				]*/
			}
		},
		computed: {
			categories() {
				return this.$store.state.common.sections;
			},
			item() {
				return this.$store.state.page.CategoryController.section;
			},
			flavour() {
				return this.$store.state.page.CategoryController.flavour;
			},
			products() {
				//return this.$store.state.page.CategoryController.items.filter(el=>!el.IS_SEASON);
				return this.$store.state.page.CategoryController.items;
			},
			/*filters() {
				//return this.filters.filter(f => this.products.some(p => p.PCS && Number(p.PCS) >= f.min && Number(p.PCS) <= f.max));
			},*/
			filteredProducts() {
				//if (!this.filters?.length) return this.items;
				const filters = this.filters?.filter(f => f.isActive);
				if (!filters?.length) return this.products;
				return this.products.filter(p => filters.some(f => p.PCS && Number(p.PCS) >= f.min && Number(p.PCS) <= f.max));
			},
      isPralineCategory() {
        return Number(this.item.ID) === PRALINE_CATEGORY_ID;
      },
      isMiniPralineCategory() {
        return Number(this.item.ID) === MINI_PRALINE_CATEGORY_ID;
      }
		},
		methods: {
			initFilters() {
				if ( !this.item.UF_FILTERS) return null;
				const filters = this.item.UF_FILTERS.split(";").map(el=>{
					el = el.trim();
					let name = el;
					let min = 0, max = 0;
					if ( el.match(/^\d+\-\d+$/)) {
						min = Number(el.replace(/^(\d+)\-\d+$/, "$1"));
						max = Number(el.replace(/^\d+\-(\d+)$/, "$1"));
					}
					else if ( el.match(/^\d+\-$/)) {
						min = 0;
						max = Number(el.replace(/^(\d+)\-$/, "$1"));
					}
					else if ( el.match(/^\d+\+$/)) {
						min = Number(el.replace(/^(\d+)\+$/, "$1"));
						max = 1000;
					}
					return {
						name,
						min,
						max,
						isActive: false
					}
				});
				console.log("FILTERS: ", filters);
				this.filters = filters;
			}
		},
		mounted() {
			this.initFilters();
		}
	}
</script>

<style lang="scss">
	.category {

		&__header {
			position: relative;
			z-index: $z-highest - 2;

			margin: 20px 0;
			display: flex;
			justify-content: center;

			@include up($sm) {
				margin: 40px 0;
			}

			& > div {
				background: $white-alpha2;
				.size4 {
					border-top: 1px solid $golden;
					border-bottom: 1px solid $golden;
					text-align: center;
					color: $black;
					padding: 16px 8px;
					line-height: 20px;
          max-width: 565px;
				}
			}
		}

		&__filter {
			border-bottom: 1px solid $golden;
			display: flex;
			justify-content: center;

			ul {
				display: flex;
				//flex-direction: column;
				//flex-direction: row;
				justify-content: center;
				align-content: center;
				padding: 8px !important;
				text-transform: uppercase;

				@include up($sm) {
					padding: 10px !important;
				}

				li {
					position: relative;
					cursor: pointer;
					display: flex;
					align-items: center;
					padding: 2px 4px !important;
					margin-left: 12px !important;

					&:first-child {
						margin-left: 0 !important;
					}

					&.active, &:hover {
						color: $golden;

						svg {
							opacity: 1;
							fill: $golden;
						}
					}

					svg {
						flex-shrink: 0;
						width: 16px;
						height: 16px;
						/*left: 10px;
						top: 20px;
						right: unset;*/
						margin-right: 8px;
						//fill: $default-text-color;

						opacity: 0.5;
						@include transition();
					}
				}
			}

			&__dot {
				display: none;
				width: 3px;
				height: 3px;
				border-radius: 100%;
				background: $black-50;
				margin: 0 12px;

				@include up($sm) {
					display: inline-block;
				}
			}
		}


		/*&-animation {
			//position: relative;
			//height: 180px;
			//margin-top: 30px;

			img, div {
				position: absolute;
				opacity: 0;
			}

			@include up($md) {
				//height: auto;
				//margin-top: 0;
				position: absolute;

				width: 0;
				//max-width: $lg;
				height: 480px;

				left: 50%;
				top: 100px;
				transform: translate(-50%) scale(1);
			}

			!*&-clip {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) scale(0.55);

				@include up($md) {
					top: unset;
					left: unset;
					transform: translate(-27%) scale(0.7);
					position: relative;
				}

				@include up($lg) {
					top: unset;
					left: unset;
					transform: translate(-33%) scale(0.9);
					position: relative;
				}
			}*!
		}*/
	}
</style>