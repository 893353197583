<template>
	<div class="text-center d-flex flex-column align-center py-6 py-md-9 product-item"
		 :class="{loaded:item.loaded}">
		<div class="product-item__badge">
			<div v-if="item.NEW">new</div>
			<div class="limited" v-if="item.LIMITED">édition limitée</div>
		</div>
		<div class="product-item__self text-center d-flex flex-column align-center " :class="{loaded:item.loaded}">
			<router-link :to="to"
						 :style="isMD && item.PREVIEW_STYLE?item.PREVIEW_STYLE:null"
						 class="product-item__image" :class="imageClass">
				<v-img :src="item.PREVIEW_PICTURE" :alt="item.NAME"
					   :class="{image:item.HOVER}" contain
					   @load="onLoaded"/>
				<v-img v-if="item.HOVER" :src="item.HOVER" :alt="item.name" contain
					   class="hover" :style="isMD && item.HOVER_STYLE?item.HOVER_STYLE:null"/>
			</router-link>

			<div class="mt-8 product-item__text relative z-index-2">
				<router-link :to="to">
					<h5 v-html="nameHtml"></h5>
					<div v-if="subnameHtml" class="size2" v-html="subnameHtml"></div>
				</router-link>
				<div class="d-flex justify-center mt-3">
					<div class="h5 product-item__price">{{ item.PRICE | price }}
						<span>₽</span>
					</div>
					<button v-if="!item.NO_SPB || !item.NO_MSK" class="product-item__btn relative h5"
							:class="{'loading anim-shake-y':isAddToBasketLoading,'in-basket':isInBasket}"
							@click="onAddToBasket" title="В корзину"
					>
						<span class="basket-info">
							<svgicon name="shop"/>
							<span v-if="isInBasket" class="basket-amount">
								{{ countBasketProducts }}
							</span>
						</span>
					</button>
				</div>
				<div class="product-item__avaliable mt-3 size4 d-inline-block">
					<div v-if="item.NO_SPB && item.NO_MSK">Sold out</div>
					<div v-else-if="item.NO_SPB">Только в Москве</div>
					<div v-else-if="item.NO_MSK">Только в Петербурге</div>
					<div v-else class="d-none"></div>
				</div>
			</div>
		</div>

		<div v-if="item.ANIMATION_ID==='1' && isAnimationVisible" class="product-list__animation">
			<category-animation-1 class="d-none z-index-2 d-md-block"/>
			<category-animation-1-bg class="d-none d-md-block"/>
		</div>

	</div>
</template>

<script>
import Vue from "vue";
import vp from "@/mixins/vp";

export default {
	name: "product-item",
	props: ["item", "category", "flavour", "offer"],
	mixins: [vp],
	data() {
		return {
			isAddToBasketLoading: false
		}
	},
	computed: {

		basket() {
			return this.$store.state.session.basket;
		},
		imageClass() {
			if (!this.item.COLS) return null;
			//if (!this.isMD) return null;
			return "cols-" + this.item.COLS;
		},
		to() {
			if (this.flavour) {
				return {
					name: 'product',
					params: {
						sectionCode: this.categoryCode,
						code: this.item.CODE
					},
					query: {
						flavourId: this.flavour.ID
					}

				};
			}
			return {name: 'product', params: {sectionCode: this.categoryCode, code: this.item.CODE}};
		},
		nameHtml() {
			const item = this.item;
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			let name = item.NAME;
			name = name.replace(/(^.+?)(в )?(горьком|молочном).+$/is, "$1");
			name = name.replace(/(^.+?)filled with .+$/is, "$1");
			name = name.replace(/(^.+?)\d+ g$/is, "$1");
			name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
			/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
			  name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
			  if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
			}
			else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

			return name;
		},
		subnameHtml() {
			const item = this.item;
			if (item.SUBNAME) return item.SUBNAME;
			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			let choco = null;
			if (item.NAME.match(/.+?filled with .+$/)) {
				choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/.+?(в )?(горьком|молочном).+$/)) {
				choco = item.NAME.replace(/.+?((в )?(горьком|молочном).+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/\d+ g$/)) {
				// todo make this related to item.WEIGHT
				choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
			} else if (item.NAME.match(/\d+ pcs$/)) {
				choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
				if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
			}

			return choco;
		},
		categories() {
			return this.$store.state.common.sections;
		},
		categoryCode() {
			if (this.category) return this.category.CODE;
			/*console.log("CATS: ", this.categories);
			console.log("ITEM: ", this.item);*/
			return this.categories.find(el => el.ID === this.item.IBLOCK_SECTION_ID).CODE;
		},
		offers() {
			return this.$store.state.page?.CategoryController.offers;
		},
		offerID() {
			if (this.offers) return this.offers.find(el => el.CML2_LINK === this.item.ID)?.CML2_LINK;
		},
		isInBasket() {
			return !!this.basket?.some(el => Number(el.PRODUCT_ID) === Number(this.item.ID));
		},
		countBasketProducts() {
			const item = this.basket?.find(el => Number(el.PRODUCT_ID) === Number(this.item.ID));
			return item?.AMOUNT || 0;
		},
	},
	methods: {
		onLoaded() {
			setTimeout(() => Vue.set(this.item, "loaded", true), 300);
		},
		onAddToBasket() {
			//if (this.isInBasket) this.$router.push({name: 'basket'});
			//else
			if (this.item.BOX_SIZE == null && this.item.ID !== this.offerID) {
				this.isAddToBasketLoading = true;
				this.$store.dispatch('post', {
					action: 'BasketAddController',
					params: {
						productId: this.item.ID,
						amount: 1
					},
				}).then((result) => {
					this.$store.commit('setSession', result);
				}).catch((error) => {
					this.error = error?.message;
					//this.isSnackError = true;
				}).finally(() => {
					setTimeout(() => {
						this.isAddToBasketLoading = false;
					}, 1000);
				});
			} else {
				this.$router.push(this.to);
			}
		},
	},
	mounted() {

	}
}
</script>

<style lang="scss">
.product-item {
	position: relative;

	&__badge {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		display: flex;

		div {
			padding: 2px 8px;
			color: #FFF;
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 0.96px;
			text-transform: uppercase;
			//border: 1px solid #D6BF96;
			background: #DFC391;
			margin-bottom: 4px;
			width: fit-content;
		}

		& .limited {
			background: #9FA37B;
		}
	}

	&__avaliable {
		div {
			font-family: $body-font-family;
			text-transform: uppercase;
			background-color: #eaeaea;
			padding: 4px 8px;
		}
	}

	&__self {
		position: relative;
		max-width: 280px;
		@include transition();
		opacity: 0;
		transform: translateY(-10px);
		z-index: 1;

		&.loaded {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&__image {
		position: relative;
		display: block;

		&:hover {
			opacity: unset;
		}

		.v-image {
			//mix-blend-mode: multiply;
			filter: brightness(1.01); // для скрытия серго фона jpg
			@include transition();

			@include up($md) {
				&:not(.image):not(.hover) {
					&:hover {
						filter: brightness(1.02) contrast(1.02);
						transform: translateY(-2px); // rotate(1deg);
					}
				}

				&.image {
					z-index: 1;

					&:hover {
						opacity: 0;

						~ .hover {
							opacity: 1;
						}
					}
				}
			}

			&.hover {
				display: none;
				position: absolute;
				top: 50%;
				left: 50%;
				//z-index: -1;
				opacity: 0;
				//width: 100%;
				transform: translateX(-50%) translateY(-50%);
				mix-blend-mode: multiply;

				@include up($md) {
					display: block;
				}
			}
		}

		// по умолчанию - всегда выравнивание по физическому размеру картинок

		// для кастомных колонок 6+ на мобиле ограничиваем ширину
		&.cols-6, &.cols-8, &.cols-12 {
			width: 100%;
			max-width: 320px;

			@include up($md) {
				width: unset;
				max-width: unset;
			}

		}
	}

	&__price {
		color: $black !important;

		span {
			font-size: 14px;
			line-height: 20px;
		}
	}

	&__text {
		padding: 0 8px;

		a {
			display: block;
		}
	}

	&__animation {
		display: none;
		@include up($md) {
			display: block;
		}
	}

	&__btn {
		display: flex;
		align-items: center;
		color: $golden;
		margin-left: 8px;
		@include transition();

		&:hover {
			svg {
				fill: $black;
				width: 24px;
				height: 24px;
			}
		}

		svg {
			position: absolute;
			z-index: 1;
			width: 20px;
			height: 20px;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			@include transition();
		}

		&.in-basket {
			svg {
				width: 24px;
				height: 24px;
				//top: 37%;
			}
		}

		.basket-info {
			position: absolute;
			width: 32px;
			height: 32px;
		}

		.basket-amount {
			//position: relative;
			position: absolute;
			top: 2px;
			right: -1px;
			//transform: translateX(-40%);
			z-index: 2;
			/*font-size: 14px;
			color: $black;*/
			//z-index: 3;
			//width: 16px;
			text-align: center;
			letter-spacing: .5px;
			padding: 0 5px;
			min-width: 16px;
			height: 16px;
			line-height: 16px;
			background: $red1;
			border-radius: 8px;
			font-size: 12px;
			font-weight: $fw-book;
			color: $white;
		}

		/*.loader {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			background: $golden;
			width:16px;
			height: 16px;
			border-radius: 50%;
			animation: anim-scale-in .5s;
			animation-fill-mode: forwards;
			opacity: 0;
			//@include transition-long()
		}*/

		/*&.loading {
			.loader {
				//transform: translateX(-50%) translateY(-50%) scale(3);

			}
		}*/
	}
}
</style>