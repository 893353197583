<template>
  <div class="product-list" v-scroll="onScroll">
    <v-container>
      <v-row justify="center" class="align-self-end" v-for="(items, index) in rows" :key="'row'+index">
        <v-col v-for="(item, colIndex) in items"
               :key="'col'+item.ID"
               cols="12" class="d-flex justify-center" :class="{relative:item.ANIMATION_ID}"
               :style="{'z-index':32-colIndex}"
               :md="colsMD(item)"
               :offset-md="offsetMD(item)">

          <product-item :item="item" :category="category" :ref="'item'+item.ID" :flavour="flavour" :offer="offer"
                        class="z-index-1"/>

          <transition name="fade">
            <div v-if="item.VIDEO_LINK && isAnimationVisible" class="product-list__animation">
            <animation class="d-none d-md-block"
                       :video="item.VIDEO_LINK"
                       :position="'height:' + item.VIDEO_HEIGHT + 'px;' + 'top:' + item.VIDEO_TOP + '%;'"
                       :top="'top:' + item.VIDEO_TOP + '%'"/>
            </div>
          </transition>


        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductItem from "./product-item";

import Animation from "@/views/Category/animations/main-animation";

export default {
  name: "product-list",
  components: {
    ProductItem,
    Animation,

  },
  props: ["category", "items", "flavour", "offer"],
  data() {
    return {
      isAnimationVisible: true,
    }
  },
  computed: {
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
    rows() {
      const rows = [];
      let items = [];
      this.items.forEach(el => {
        if (el.WITH_NEW_ROW && items.length) {
          rows.push(items);
          items = [el];
        } else items.push(el);
      });
      rows.push(items);
      return rows;
    },
    animatedItem() {
      return this.items.find(el => !!el.ANIMATION_ID);
    }
  },
  methods: {
    colsMD(item) {
      if (!item.COLS) return 4;
      return parseInt(item.COLS);
    },
    offsetMD(item) {
      if (!item.OFFSET) return null;
      return parseInt(item.OFFSET);
    },
    onScroll() {
      const item = this.animatedItem;
      if (!item) return;
      const ref = this.$refs['item' + item.ID];
      if (!ref) return;
      if (!ref[0]) return;
      if (!ref[0].$el) return;
      // todo find out why [0] is needed?
      const rect = ref[0].$el.getBoundingClientRect();
      //this.isAnimationVisible = rect.top < window.innerHeight * .75 && rect.bottom > window.innerHeight * .5;
    },
  }
}
</script>

<style lang="scss">
.product-list {
  .container {
    max-width: 1100px;
    min-height: 800px;
  }


  &__animation {
    display: none;
    @include up($md) {
      display: block;
      position: relative;
    }
  }

  // todo naming
  /*.category {
    &-animation {
      @include up($md) {
        //height: auto;
        //margin-top: 0;
        position: absolute;

        width: 0;
        //max-width: $lg;
        height: 480px;

        left: 50%;
        top: 0;
        transform: translate(-50%) scale(1);
      }
    }
  }*/
}
</style>